import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Home from './components/Home'
import Playlist from './components/Playlist'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home
  },
  {
    name: 'Playlist',
    path: '/playlist/:name',
    component: Playlist
  }
]

export default new Router({
  mode: 'history',
  routes
}) 