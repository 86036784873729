<template>
  <v-app class="ma-0 pa-0">
    <v-main class="ma-0 pa-0">
      <router-view style="height: 93vh;" />

      <div class="d-flex justify-center align-center primary white--text text-h6 text-decoration-underline"
        style="height: 7vh;" @click="openSocials()">

        Seguimi su tutti i socials!
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  methods: {
    openSocials() {
      const href = 'https://linktr.ee/iamiride'
      const link = document.createElement('a')

      link.href = href
      link.setAttribute('target', '_blank')
      link.click()

      // eslint-disable-next-line no-undef
      _paq.push(['trackLink', href, 'link'])
    }
  }
}
</script>
