<template>
  <v-container class="pa-0 d-flex flex-column align-center header" style="height: 100%;">

    <!-- Page header -->
    <div class="mt-5 d-flex flex-column align-center">
      <v-img :src="require('../assets/logo.png')" contain max-width="124px" />
      <span class="mt-4 text-h5 grey--text text--lighten-5">{{ playlist.name }}</span>
    </div>

    <!-- Main list wrapper-->
    <v-container class="mt-5 pa-3 d-flex flex-column song elevation-15" fluid
      style="height: 100%; row-gap: 14px; overflow-y: auto;">

      <!-- List item -->
      <div @click="openSong(song, false)" v-for="(song, i) in playlist.songs" :key="i"
        class="d-flex justify-space-between" style="min-height: 84px;">

        <!-- Left col (img + texts)-->
        <div class="d-flex">
          <v-img :src="coverUrl(song)" contain max-width="84px" />

          <div class="ml-2 d-flex flex-column justify-space-between pa-0">
            <div class="d-flex flex-column">
              <span class="text-body-1 grey--text text--lighten-4">{{ song.title }}</span>
              <span class="text-body-2 grey--text text--lighten-1">{{ song.artist }}</span>
            </div>
            <span class="text-caption grey--text text--darken-1">{{ formatLength(song) }}</span>
          </div>
        </div>


        <!-- Right col (cta) -->
        <v-btn icon @click.stop="openSong(song, true)" class="align-self-center">
          <v-icon large color="success">mdi-download</v-icon>
        </v-btn>
      </div>
    </v-container>

  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Playlist',

  data: () => ({
    playlist: {}
  }),

  mounted: function () {
    const _ = this

    const name = _.$route.params.name

    _.$http.get(`/data/playlists/${name}.json`)
      .then(res => {
        _.playlist = res.data
      })
  },

  methods: {
    formatLength(song) {
      if (!song.length) return '00:00'

      const l = song.length

      const m = (Math.floor(l / 60)).toString().padStart(2, '0')
      const s = (l % 60).toString().padStart(2, '0')

      return `${m}:${s}`
    },

    coverUrl(song) {
      const _ = this

      return _.$http.baseUrl + song.cover
    },

    openSong(song, download = false) {
      const _ = this
      const link = document.createElement('a')
      const href = _.$http.baseUrl + song.href

      if (download) link.setAttribute('download', `${song.artist} - ${song.title}`);

      link.href = href
      link.setAttribute('target', '_blank')
      link.click()

      // eslint-disable-next-line no-undef
      _paq.push(["trackLink", href, "download"])
    }
  }
}
</script>
<style>
.header {
  background: rgb(164, 99, 199);
  background: linear-gradient(157deg, rgba(164, 99, 199, 1) 0%, rgba(34, 28, 150, 1) 60%);
}

.song {
  background: rgba(88, 88, 88, 0.6);
}
</style>