<template>
  <v-container class="pa-0 d-flex flex-column align-center header" style="height: 100%;">

  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',

  mounted: function () {
    const href = 'https://linktr.ee/iamiride'

    document.location = href
  }
}
</script>